import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const Home = () => {
  const history = useHistory();
  useEffect(() => {
    setTimeout(() => {
      history.push("/docs/1-new-stock");
    }, 3000);
  }, []);
  return (
    <>
      <div className="home">
        <h2>
          Korea Investment Partners
          <br />
          for you
        </h2>
      </div>
    </>
  );
};

export default Home;
