export const convertTimeFlowToTitle = (timeflow) => {
  if (timeflow === "invt_flow_1") return "투자전 서류";
  if (timeflow === "invt_flow_2") return "투자직전 서류";
  if (timeflow === "invt_flow_3") return "투자후 서류";

  if (timeflow === "invt_flow_2_desc")
    return "투자집행 1영업일 전까지 제출 요청 서류";
  if (timeflow === "invt_flow_3_desc") return "투자집행 후 2주 이내 제출 서류";
};

// 언어별 적용방법 (json 키값 및 라이브러리)
// react-intl
// ko.json
// en.json
// {
//   "hi" : "안녕하세요"
// }
// {
//  "hi": "hello"
// }
// Daniel Hong오후 10:09
// <FormattedMessage id = "hi" />

export const convertStringToInlineStyle = (str) => {};
