// import CheckedBox from "./components/CheckedBox";
// import ColorPicker from "./components/ColorPicker";
// import PrimitiveReference from "./components/PrimitiveReference";
// import RealState from "./components/RealState";
// import StateInitTest from "./components/StateInitTest";
// import "./components/reset.css";
import "./style.css";
import "./components/home.css";
import { Link, Route, Switch } from "react-router-dom";
import DocsRouter from "./router/DocsRouter";
import Home from "./components/Home";
import LoginPage from "./test/LoginPage";

// 강제 배포를 위해... 왜 오류가 나오지...
function App() {
  return (
    <>
      <Switch>
        <Route exact path="/" component={Home} />

        {/* 중요 sub루트를 활용하기 위해선 exact 절대 사용금지 */}
        <Route path="/docs" component={DocsRouter} />
        <Route path="/test/login" component={LoginPage} />
        <Route
          render={({ location }) => (
            <div className="not-found">
              <h2>404. That’s an error.</h2>
              <br />
              <p>
                The requested URL <br />
                <em>request.kipvc.com{location.pathname}</em>
                <br /> was not found on this server. <br />
                That’s all we know.
              </p>
              <button
                onClick={() => {
                  window.history.back(); // window.history 는 import 없이 사용가능
                  // console.log(location); // 도메인 정보는 location에 없다.
                }}
              >
                back
              </button>
            </div>
          )}
        />
      </Switch>
    </>
  );
}

export default App;
