import React, { useEffect, useState } from "react";
import { GoogleSpreadsheet } from "google-spreadsheet";
import cred from "../saconfig2.json";
import { Link, Route, Switch } from "react-router-dom";
import DocsInvestment from "../components/DocsInvestment";
import Gnb from "../components/Gnb";

const DocsRouter = () => {
  const [doclist, setDoclist] = useState([]);
  // const [choiceDocsType, setChoiceDocsType] = useState("");

  useEffect(() => {
    (async function () {
      const doc = new GoogleSpreadsheet(
        "1yxM9XDYMsCKLsS0j89tVqqI38jVIGXjDUEBaISgIeqA"
      );
      await doc.useServiceAccountAuth(cred);
      await doc.loadInfo();
      const sheet = doc.sheetsByTitle["investment_documents"];
      setDoclist(await sheet.getRows());
    })();
  }, []);

  return (
    <>
      {/* GNB에 props 없이 useLocation 으로 정리된다. */}
      {/* <GNB /> */}
      <Gnb />
      <Switch>
        <Route
          exact
          path="/docs/1-new-stock"
          render={() => <DocsInvestment doclist={doclist} />}
        />
        <Route
          exact
          path="/docs/2-old-stock"
          render={() => <DocsInvestment doclist={doclist} />}
        />
        <Route
          exact
          path="/docs/3-new-bond"
          render={() => <DocsInvestment doclist={doclist} />}
        />
        <Route
          exact
          path="/docs/4-old-bond"
          render={() => <DocsInvestment doclist={doclist} />}
        />
      </Switch>
    </>
  );
};

export default DocsRouter;
