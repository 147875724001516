import React, { useEffect, useState } from "react";
import "./Gnb.css";
import { Link, useLocation } from "react-router-dom";
import icon from "../assets/logo_eng_crop.jpg";

const Gnb = () => {
  const location = useLocation();
  const path = location.pathname;

  return (
    <div className="gnb">
      <div className="gnb-left">
        {/* 244 x 53 */}
        {/* <img style={{ height: "40px" }} src="images/logo_eng_crop.jpg" /> */}
        <img style={{ height: "40px" }} src={icon} />
      </div>
      <div className="gnb-right">
        <div className={`item ${path === "/docs/1-new-stock" ? "on" : ""}`}>
          <Link to="/docs/1-new-stock">신주</Link>
        </div>
        <div className={`item ${path === "/docs/2-old-stock" ? "on" : ""}`}>
          <Link to="/docs/2-old-stock">구주</Link>
        </div>
        <div className={`item ${path === "/docs/3-new-bond" ? "on" : ""}`}>
          <Link to="/docs/3-new-bond">신권</Link>
        </div>
        <div className={`item ${path === "/docs/4-old-bond" ? "on" : ""}`}>
          <Link to="/docs/4-old-bond">구권</Link>
        </div>
      </div>
    </div>
  );
};

export default Gnb;
