import React, { useEffect, useState } from "react";
import { convertTimeFlowToTitle } from "../utils/Utils";
import { useLocation } from "react-router-dom";
// import { withRouter } from "react-router-dom";

import "./DocsInvestment.css";

// const DocsInvestment = ({ doclist, location, match }) => {
const DocsInvestment = ({ doclist }) => {
  const location = useLocation();
  const [listFlow_1, setListFlow_1] = useState([]);
  const [listFlow_2, setListFlow_2] = useState([]);
  const [listFlow_3, setListFlow_3] = useState([]);

  // console.log(location);
  // const docsType = String(match.path).split("/")[2];
  const docsType = location.pathname.split("/")[2];

  useEffect(() => {
    setListFlow_1(
      doclist.filter(
        (item) => item.invttype === docsType && item.timeflow === "invt-flow-1"
      )
    );
    setListFlow_2(
      doclist.filter(
        (item) => item.invttype === docsType && item.timeflow === "invt-flow-2"
      )
    );
    setListFlow_3(
      doclist.filter(
        (item) => item.invttype === docsType && item.timeflow === "invt-flow-3"
      )
    );
  }, [doclist, docsType]); // 중요!! 서버로 부터 값을 받아오기 전에 렌더링 해보면 다시는 안들어와 짐 (dependeny 주의!!)

  // console.log(match);
  // console.log(location);
  return (
    // <div>ddd</div>
    <div
      className={`${
        doclist.length !== 0 ? "cotent-container" : "cotent-container center"
      }`}
    >
      {doclist.length === 0 ? (
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        <>
          {listFlow_1.length > 0 && (
            <div className="container">
              <div className="doc-group-title">
                <h2>
                  {convertTimeFlowToTitle("invt_flow_1")} ({listFlow_1.length})
                </h2>
              </div>
              {listFlow_1.map((item, idx) => {
                const object = item.style ? JSON.parse(item.style) : undefined;
                const download = item.download;
                return (
                  <div className="doc" key={idx}>
                    {download ? (
                      <a
                        // href={window.encodeURI(download)}
                        href={download}
                        className="doc-title"
                        style={object}
                        download
                      >
                        {idx + 1}. {item.filename}
                      </a>
                    ) : (
                      <div className="doc-title" style={object}>
                        {idx + 1}. {item.filename}
                      </div>
                    )}

                    {/* dangerouslySetInnerHTML */}
                    {/* 사용자가 악용하여 페이지를 망가트릴 수 있음 */}
                    <div
                      dangerouslySetInnerHTML={{ __html: item.desc }}
                      className="doc-desc"
                    ></div>
                  </div>
                );
              })}
            </div>
          )}

          {listFlow_2.length > 0 && (
            <div className="container">
              <div className="doc-group-title">
                <h2>
                  {convertTimeFlowToTitle("invt_flow_2")} ({listFlow_2.length})
                </h2>
                <h5>{convertTimeFlowToTitle("invt_flow_2_desc")}</h5>
              </div>
              {listFlow_2.map((item, idx) => {
                const object = item.style ? JSON.parse(item.style) : undefined;
                const download = item.download;
                return (
                  <div className="doc" key={idx}>
                    {download ? (
                      <a
                        // href={window.encodeURI(download)}
                        href={download}
                        className="doc-title"
                        style={object}
                        download
                      >
                        {idx + 1}. {item.filename}
                      </a>
                    ) : (
                      <div className="doc-title" style={object}>
                        {idx + 1}. {item.filename}
                      </div>
                    )}

                    <div
                      dangerouslySetInnerHTML={{ __html: item.desc }}
                      className="doc-desc"
                    ></div>
                  </div>
                );
              })}
            </div>
          )}

          {listFlow_3.length > 0 && (
            <div className="container">
              <div className="doc-group-title">
                <h2>
                  {convertTimeFlowToTitle("invt_flow_3")} ({listFlow_3.length})
                </h2>
                <h5>{convertTimeFlowToTitle("invt_flow_3_desc")}</h5>
              </div>
              {listFlow_3.map((item, idx) => {
                const object = item.style ? JSON.parse(item.style) : undefined;
                const download = item.download;
                return (
                  <div className="doc" key={idx}>
                    {download ? (
                      <a
                        // href={window.encodeURI(download)}
                        href={download}
                        className="doc-title"
                        style={object}
                        download
                      >
                        {idx + 1}. {item.filename}
                      </a>
                    ) : (
                      <div className="doc-title" style={object}>
                        {idx + 1}. {item.filename}
                      </div>
                    )}

                    <div
                      dangerouslySetInnerHTML={{ __html: item.desc }}
                      className="doc-desc"
                    ></div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DocsInvestment;
// export default withRouter(DocsInvestment);
