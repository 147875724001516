import React, { useEffect } from "react";
import styles from "./LoginPage.module.scss";
import icon from "../assets/logo_eng_crop.jpg";
// import GlobalStyle from "./Global";
import html2pdf from "html2pdf.js";

const LoginPage = () => {
  const onPrint4pdf = () => {
    const element = document.getElementById("element-to-print");
    const opt = {
      margin: 1,
      // filename: new Date(),
      filename: "Test_" + new Date().toLocaleDateString() + "pdf",
      image: { type: "jpeg", quality: 0.98 },
      // pagebreak: { mode: ["css", "legacy"] },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    console.log("dd");
    const worker = html2pdf().set(opt).from(element).save();
  };
  return (
    <>
      {/* <GlobalStyle /> */}
      <div className={styles.container}>
        {/* <button id="authorize_button" style={{ display: "none" }}>
          Authorize
        </button>
        <button id="signout_button" style={{ display: "none" }}>
          Sign Out
        </button> */}

        <img src={icon} />
        <button onClick={onPrint4pdf}>pdf 출력</button>
        <h2>Login to KIP</h2>
        <div id="element-to-print" className={styles.login}>
          <div className={styles.login_id}>
            <label htmlFor="id_field">User's identifier</label>
            <input id="id_field" className={styles.login_input} />
          </div>

          <div className={styles.login_pw}>
            <label htmlFor="pw_field">Password</label>
            <input
              type="password"
              id="pw_field"
              className={styles.login_input}
            />
          </div>

          <button className={styles.login_login_button}>Login</button>
          <div className={styles.forgot_box}>
            <div>Forgot ID?</div>
            <div>Forgot password?</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
